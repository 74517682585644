<template>
  <div>
    <navbar />
    <image-implant />
    <implant-composant class="mt100" />
    <implant-passeport class="mt100" />
    <foot class="mt100" />
  </div>
</template>

<script>
import foot from "@/components/FooterBis.vue";
import navbar from "@/components/NavBar.vue";
import ImplantComposant from "../../components/PageImplant/ImplantComposant.vue";
import ImplantPasseport from "../../components/PageImplant/ImplantsPasseport.vue";
import imageImplant from "../../components/PageImplant/ImplantImage.vue";

export default {
  components: {
    foot,
    navbar,
    imageImplant,
    ImplantPasseport,
    ImplantComposant,
  },
  metaInfo() {
    return {
      title: this.$t("pageImplant.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("pageImplant.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("pageImplant.metaKeywords"),
        },
      ],
    };
  },
};
</script>
