<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="10" xl="8">
        <div class="implant-container shadow">
          <div class="list-container">
            <ul class="row-around item-implant">
              <li>
                <button @click="button = 1">
                  <h3 :class="{ active: button == 1 }">
                    {{ $t("pageImplant.sections.activity.button") }}
                  </h3>
                </button>
                <div :class="{ active: button == 1 }"></div>
              </li>
              <li>
                <button @click="button = 3">
                  <h3 :class="{ active: button == 3 }">
                    {{ $t("pageImplant.sections.ppg.button") }}
                  </h3>
                </button>
                <div :class="{ active: button == 3 }"></div>
              </li>
              <li>
                <button @click="button = 4">
                  <h3 :class="{ active: button == 4 }">
                    {{ $t("pageImplant.sections.lab.button") }}
                  </h3>
                </button>
                <div :class="{ active: button == 4 }"></div>
              </li>
              <li>
                <button @click="button = 5">
                  <h3 :class="{ active: button == 5 }">
                    {{ $t("pageImplant.sections.ecg.button") }}
                  </h3>
                </button>
                <div :class="{ active: button == 5 }"></div>
              </li>
            </ul>
          </div>
          <div class="implant-container-inferior row-around">
            <div class="part-1">
              <div>
                <h4 class="tilte-part">{{ $t("pageImplant.sensors.title") }}</h4>
                <ul class="list-capteurs display-xl background-capteurs" style="height: 300px">
                  <li v-show="true" :class="{ activeico: true }">
                    <img src="@/assets/ico/temp.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.temperature") }}</p>
                  </li>
                  <li v-show="true" :class="{ activeico: true }">
                    <img src="@/assets/ico/acc.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.accelerometer") }}</p>
                  </li>
                  <li :class="{ activeico: button == 5 }">
                    <img src="@/assets/ico/Gyro.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.gyroscope") }}</p>
                  </li>
                  <li :class="{ activeico: button == 3 }">
                    <img src="@/assets/ico/ppg.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.ppg") }}</p>
                  </li>
                  <li :class="{ activeico: button == 4 || button == 5 }">
                    <img src="@/assets/ico/Spectrometrie.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.spectroscopy") }}</p>
                  </li>
                  <li :class="{ activeico: button == 5 }">
                    <img src="@/assets/ico/ECG.webp" alt="" />
                    <p>{{ $t("pageImplant.sensors.list.ecg") }}</p>
                  </li>
                </ul>
                <div class="display-xs background-capteurs">
                  <ul class="list-capteurs">
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/temp.webp" alt="" />
                      <p>{{ $t("pageImplant.sensors.list.temperature") }}</p>
                    </li>
                    <li v-show="true" :class="{ activeico: true }">
                      <img src="@/assets/ico/acc.webp" alt="" />
                      <p>{{ $t("pageImplant.sensors.list.accelerometer") }}</p>
                    </li>
                  </ul>
                  <ul class="list-capteurs">
                    <li :class="{ activeico: button == 3 }">
                      <img src="@/assets/ico/ppg.webp" alt="" />
                      <p>{{ $t("pageImplant.sensors.list.ppg") }}</p>
                    </li>
                    <li :class="{ activeico: button == 4 || button == 5 }">
                      <img src="@/assets/ico/Spectrometrie.webp" alt="" />
                      <p>{{ $t("pageImplant.sensors.list.spectroscopy") }}</p>
                    </li>
                    <li :class="{ activeico: button == 5 }">
                      <img src="@/assets/ico/ECG.webp" alt="" />
                      <p>{{ $t("pageImplant.sensors.list.ecg") }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="spectroscopie" :class="{ activespectro: button == 3 || button == 4 || button == 5 }">
                <h4 class="tilte-part">{{ $t("pageImplant.led.title") }}</h4>
                <ul class="list-spectroscopie row-around" :class="{ activsmall: button == 3 }">
                  <li :class="{ activespectro: button == 4 || button == 5 }">
                    <button id="delta1"></button>
                    <p>{{ $t("pageImplant.led.list.uva.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.uva.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 4 || button == 5 }">
                    <button id="delta2"></button>
                    <p>{{ $t("pageImplant.led.list.blue.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.blue.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 4 || button == 5 }">
                    <button id="delta3"></button>
                    <p>{{ $t("pageImplant.led.list.green.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.green.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 3 || button == 4 || button == 5 }" style="margin-right: 12px">
                    <button id="delta4"></button>
                    <p>{{ $t("pageImplant.led.list.red.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.red.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 3 || button == 4 || button == 5 }">
                    <button id="delta5"></button>
                    <p>{{ $t("pageImplant.led.list.infrared1.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.infrared1.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 4 || button == 5 }">
                    <button id="delta6"></button>
                    <p>{{ $t("pageImplant.led.list.infrared2.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.infrared2.wavelength") }}</p>
                  </li>
                  <li :class="{ activespectro: button == 4 || button == 5 }">
                    <button id="delta7"></button>
                    <p>{{ $t("pageImplant.led.list.infrared3.name") }}</p>
                    <p>{{ $t("pageImplant.led.list.infrared3.wavelength") }}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="part-1">
              <div class="image-implant-container">
                <img src="@/assets/images/ImplantSeul.webp" alt="" />
              </div>
            </div>
            <div class="part-2">
              <div class="capteurs">
                <h4 class="tilte-part">{{ $t("pageImplant.data.title") }}</h4>
                <div class="row-around background-capteurs margin5">
                  <ul class="list-capteurs">
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/RoueViolette.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.activity") }}</p>
                    </li>

                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/mouv.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.behavior") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/Orientation.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.tremors") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/reproduction.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.interaction") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/mémoire.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.passport") }}</p>
                    </li>
                  </ul>
                  <ul class="list-capteurs">
                    <li :class="{
                      activeico:
                        button == 2 || button == 3 || button == 4 || button == 5,
                    }">
                      <img src="@/assets/ico/coeur.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.heartRate") }}</p>
                    </li>
                    <li :class="{ activeico: button == 3 || button == 4 || button == 5 }">
                      <img src="@/assets/ico/o2.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.oxygenLevel") }}</p>
                    </li>
                    <li :class="{ activeico: button == 3 || button == 4 || button == 5 }">
                      <img src="@/assets/ico/pa.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.bloodPressure") }}</p>
                    </li>
                    <li :class="{ activeico: button == 4 || button == 5 }">
                      <img src="@/assets/ico/AnalyseSang.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.chemicalAnalysis") }}</p>
                    </li>
                    <li :class="{ activeico: button == 5 }">
                      <img src="@/assets/ico/ECG.webp" alt="" />
                      <p>{{ $t("pageImplant.data.list.ECG") }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="description">
                <div class="information-implant" :class="{ active: button == 1 }">
                  <h2 class="title-implant">{{ $t("pageImplant.implant[0].title") }}</h2>
                  <p>{{ $t("pageImplant.implant[0].dimension") }}</p>
                  <p>{{ $t("pageImplant.implant[0].texte") }}</p>
                </div>
                <div class="information-implant" :class="{ active: button == 5 }">
                  <h2 class="title-implant">{{ $t("pageImplant.implant[1].title") }}</h2>
                  <p>{{ $t("pageImplant.implant[1].dimension") }}</p>
                  <p>{{ $t("pageImplant.implant[1].texte") }}</p>
                </div>
                <div class="information-implant" :class="{ active: button == 3 }">
                  <h2 class="title-implant">{{ $t("pageImplant.implant[2].title") }}</h2>
                  <p>{{ $t("pageImplant.implant[2].dimension") }}</p>
                  <p>{{ $t("pageImplant.implant[2].texte") }}</p>
                </div>
                <div class="information-implant" :class="{ active: button == 4 }">
                  <h2 class="title-implant">{{ $t("pageImplant.implant[3].title") }}</h2>
                  <p>{{ $t("pageImplant.implant[3].dimension") }}</p>
                  <p>{{ $t("pageImplant.implant[3].texte") }}</p>
                </div>
                <div class="button-position">
                  <router-link to="/contact?subjectIndex=1" class="link-download" download>
                    {{ $t("button.datasheet") }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      button: 4,
    };
  },
  computed: {
    downloadLink() {
      return this.$i18n.locale === "fr"
        ? "/pdf/MiceTrackingDatasheetFR.pdf"
        : "/pdf/MiceTrackingDatasheetUS.pdf";
    },
  },
};
</script>

<style scoped>
ul {
  padding-left: 0px !important;
}

.implant-container {
  border-radius: 25px;
  background-color: var(--white);
  /* border: solid 1px var(--noirdoux); */
  padding: 20px;
  width: fit-content;
}

.list-container {
  margin-top: 25px;
  position: sticky;
  top: 0px;
  background-color: var(--white);
  z-index: 1000;
}

.item-implant li {
  text-align: center;
}

.item-implant button:hover {
  opacity: 0.7;
}

.item-implant li div {
  width: 0px;
  height: 3px;
  background-color: var(--vertfonce);
  transition: width 0.5s ease;
  /* Animation sur la hauteur */
}

.item-implant li div.active {
  width: 100%;
  /* Hauteur finale lors de l'animation */
}

.item-implant li h3.active {
  color: var(--vertfonce);
  /* Hauteur finale lors de l'animation */
}

.implant-container-inferior {
  margin-top: 55px;
}

.part-1 {
  width: 30%;
}

.part-2 {
  width: 40%;
}

.tilte-part {
  margin-bottom: 15px !important;
}

.image-implant-container {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.image-implant-container img {
  position: relative;
  z-index: 2;
  border-radius: 50%;
  width: 100%;
}

.spectroscopie {
  width: 80%;
  margin-top: 60px;
  opacity: 0;
  transform: translateX(20px);
  /* Positionne les éléments légèrement hors de l'écran à gauche */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.spectroscopie.activespectro {
  opacity: 1;
  transform: translateX(0px);
}

.spectroscopie h4 {
  text-align: center;
  margin-bottom: 25px;
}

.list-spectroscopie {
  position: relative;
}

.list-spectroscopie.activsmall {
  width: 50px;
  left: 46%;
}

.list-spectroscopie li {
  position: relative;
  display: inline-block;
  /* Garde les boutons alignés horizontalement */
  margin-right: 15px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  display: none;
}

.list-spectroscopie li.activespectro {
  position: relative;
  display: inline-block;
  /* Garde les boutons alignés horizontalement */
  margin-right: 15px;
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-spectroscopie li button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  opacity: 0.7;
  filter: blur(3px);
  position: relative;
  z-index: 1;
  transition: 0.5s;
}

.list-spectroscopie li button:hover {
  filter: blur(0px);
}

/* Par défaut, cacher les paragraphes */
.list-spectroscopie li p {
  display: none;
  position: absolute;
  top: 30px;
  /* Positionne le texte en dessous du bouton */
  left: 50%;
  transform: translateX(-50%);
  /* Centre le texte horizontalement */
  white-space: nowrap;
  /* Empêche le texte de se casser */
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  z-index: 2;
  opacity: 0;
  transition: 1s;
}

.list-spectroscopie li p+p {
  margin-top: 20px;
}

/* Afficher les paragraphes au survol du bouton */
.list-spectroscopie li button:hover+p,
.list-spectroscopie li button:hover+p+p {
  display: block;
  opacity: 1;
}

.list-capteurs {
  padding-left: 0px !important;
  width: 75%;
}

.background-capteurs {
  /* background-color: var(--background); */
  border-radius: 20px;
  /* border: solid 1px var(--noirdoux); */
  width: 100%;
  min-width: fit-content;
  padding: 20px;
}

.button-position {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

.list-capteurs li {
  display: none;
  justify-content: left;
  align-items: center;
  opacity: 0;
  transform: translateX(-20px);
  /* Positionne les éléments légèrement hors de l'écran à gauche */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-capteurs li.activeico {
  display: flex;
  opacity: 1;
  transform: translateX(0);
}

.list-capteurs li img {
  width: 62px;
  height: 50px;
}

.list-capteurs li p {
  margin-top: 16px;
  margin-left: 10px;
  font-family: "roboto", light;
  font-size: 16px;
}

.information-implant {
  margin-top: 30px;
  animation: fadeInUp 0.5s ease-in-out forwards;
  display: none;
}

.information-implant p {
  text-align: justify;
}

.information-implant.active {
  display: block;
}

.display-xl {
  display: block;
}

.display-xs {
  display: none;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 960px) {
  .implant-container {
    border: none;
    padding: 0 0 50px 0;
  }

  .implant-container-inferior {
    margin-top: 50px;
  }

  .list-container {
    padding: 20px 0;
  }

  .item-implant button {
    font-weight: 400;
    font-size: 28px;
  }

  .item-implant li {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .item-implant li p {
    display: none;
  }

  .item-implant li.inactive {
    transform: scale(0.7);
    /* Réduit la taille des éléments inactifs */
    opacity: 0.5;
    /* Rend les éléments inactifs moins opaques */
  }

  .item-implant li.active {
    transform: scale(1);
    /* Taille normale de l'élément actif */
  }

  .implant-container-inferior {
    display: block;
  }

  .display-xl {
    display: none;
  }

  .display-xs {
    display: flex;
    width: 90%;
    margin: 5%;
    border-radius: 10px;
  }

  .part-2,
  .part-1 {
    width: 100%;
  }

  .spectroscopie {
    width: 90%;
    margin-left: 5%;
  }

  .margin5 {
    width: 90%;
    margin: 5%;
  }

  .capteurs {
    margin-top: 50px;
  }

  .description {
    margin-top: 50px;
  }

  .background-capteurs {
    background-color: white;
  }

  .image-implant-container {
    margin-top: 50px;
  }

  .image-implant-container img {
    width: 80%;
  }

  .tilte-part {
    text-align: center;
  }

  .information-implant {
    width: 90%;
    margin: 5%;
  }

  .button-position {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .list-capteurs {
    width: 100%;
  }
}

#delta1 {
  background-color: rgb(97, 0, 255);
}

#delta2 {
  background-color: rgb(0, 114, 255);
}

#delta3 {
  background-color: rgb(173, 255, 47);
}

#delta4 {
  background-color: rgb(255, 38, 0);
}

#delta5 {
  background-color: rgb(185, 44, 19);
}

#delta6 {
  background-color: rgb(122, 0, 0);
}

#delta7 {
  background-color: rgb(0, 0, 0);
}
</style>
